<template>
    <div style="height:100%">
        <div class="page-header">注册企业管理</div>
        <div class="block tab flex">
            <div class="tab_menu flex-1">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
        <div style="height: calc(100% - 167px)">
            <ItemFactory v-if="tabIndex == 0"></ItemFactory>
            <ItemSale v-if="tabIndex == 1"></ItemSale>
            <ItemDesign v-if="tabIndex == 2"></ItemDesign>
        </div>
        
    </div>
</template>
<script>
    import { getRoles,delRol } from '@/service/purview';
    import ItemFactory from './ItemFactory.vue';
    import ItemSale from './ItemSale.vue';
    import ItemDesign from './ItemDesign.vue';
    export default {
        components: {
            ItemFactory,ItemSale,ItemDesign
        },
        data() {
            return {
                tabList:[
                    {name:"工厂"},
                    {name:"销售与公司"},
                    {name:"设计公司"},
                ],
                tabIndex:0,
            }
        },
        mounted() {
            
        },
        created() {
        },
        methods: {
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    div>>> .el-dialog {
        width: 524px;
    }
    /*----选项卡----*/
    .tab {
        padding:0 20px;
    }
    .tab_menu {
        /* background: #fff;
        padding: 0 20px;
        margin: 20px; */
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        width: 80px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        margin-right:50px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
</style>