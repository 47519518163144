<template>
    <div style="height:100%">
        <div class="tab flex">
            <div class="flex-1">
                <el-input style="max-width:250px;" placeholder="搜索企业"
                prefix-icon="el-icon-search" v-model="inputValue"></el-input>
            </div>
        </div>
        <div class="block" style="height: calc(100% - 80px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="id" label="企业ID" width="80">
                    </el-table-column>
                    <el-table-column prop="privs" label="企业名称" min-width="150">
                        <template slot-scope="scope">
                            <div>{{scope.row.name}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="认证状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat == 0">待审核</div>
                            <div v-if="scope.row.stat == 1">审核通过</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="资质状态" min-width="105">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat == 0">待审核</div>
                            <div v-if="scope.row.stat == 1">审核通过</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="实地认证" min-width="105">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat1 == 0">未认证</div>
                            <div v-if="scope.row.stat1 == 1">已认证({{scope.row.time}}到期)</div>
                            <div v-if="scope.row.stat1 == 2">已认证({{scope.row.time}}到期)</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="员工账号数" min-width="105">
                        <template slot-scope="scope">
                            <div>{{scope.row.personNum}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="150">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="goto('/purview/audit')">
                                审核材料
                            </el-button>
                            <el-button type="text" size="small" @click="goto('/purview/qual')">
                                公司资质材料
                            </el-button>
                            <el-button type="text" size="small" @click="goto('/purview/authority')">
                                员工和权限查看
                            </el-button>
                            <el-button type="text" size="small" @click="upDate(scope.row)">
                                更新实地认证状态
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.pageNumber" :page-size="queryOpt.pageCount" :total="total"></el-pagination>
            </div>
        </div>
        
    </div>
</template>
<script>
    import { getRoles,delRol } from '@/service/purview';
    export default {
        components: {
            
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                tabList:[
                    {name:"工厂"},
                    {name:"销售与公司"},
                    {name:"设计公司"},
                ],
                tabIndex:0,
                queryOpt:{
                    pageNumber:1,
                    pageCount:15,
                },
                total:0,
                list:[],
                inputValue:"",
                ruleForm:"",
            }
        },
        mounted() {
            let obj = {
                id:"111",
                name:"xxxxx家具品牌公司",
                person:"若风",
                stat:0,
                stat1:1,
                time:"2020/1/12",
                personNum:25,
            };
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
            this.list.push(obj);
        },
        created() {
            this.refreshItems();
        },
        methods: {
            goto(link) {
                this.$router.push(link)
            },
            // 选项卡
            tab(index) {
                this.tabIndex = index;
            },
            refreshItems() {
                console.log('refreshItems');
                getRoles().then(rst => {
                    // if (rst && rst.length>0) {
                    //     this.list = rst;
                    // }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            upDate() {
                this.$confirm('更新实地认证状态?', '温馨提示', { confirmButtonText: '是',
                cancelButtonText: '否', type: 'warning',
                }).then(() => {
                    this.$message.success("更新成功！");
                });
            },
            add() {
                this.popTitle = "新增日志";
                this.popVisible = true;
                this.ruleForm = {
                    privs:"",
                };
            },
            FpPerson() {
                this.popTitle = "分配人员跟进";
                this.popVisible = true;
                this.ruleForm = {
                    privs:"",
                };
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    div>>> .el-dialog {
        width: 524px;
    }
    /*----选项卡----*/
    .tab {
        padding:0 20px;
    }
    .tab_menu {
        /* background: #fff;
        padding: 0 20px;
        margin: 20px; */
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        width: 80px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        margin-right:50px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
</style>